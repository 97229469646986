import React from "react";
import LandingPage from "./pages/LandingPage/index";

const App = () => {
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default App;
